.banner-grid {
  display: grid;
  grid-template-columns: 45% 55%;
  padding: 85px 0px 0px 131px;
  align-items: center;
  background-image: url("../../public/assets/newBanner.svg");
  height: 670px;
  background-repeat: no-repeat;
  background-size: cover;
}

.d-mobile {
  display: none;
}
.banner-grid-img {
  text-align: end;
}

.banner-grid img {
  width: 100%;
  border-radius: 20px;
  max-width: 624px;
}
/*.banner-content {
    padding: 90px; 
}*/
.apart-head {
  text-align: center;
  padding: 30px;
}
.feature-sec {
  background: #fafafa;
  padding: 2px 0px 215px 0px;
}
.feature-bg img {
  width: 100%;
  transform: translateY(250px);
}
.feature-sec h4 {
  color: #1a1a1a;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 600;
  line-height: 23.864px; /* 119.318% */
  text-transform: capitalize;
}
.feature-sec p {
  color: #7e7e7e;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.left-track {
  text-align: right;
}
.first-track {
  position: relative;
  margin: 5.5rem 0rem;
  left: -2rem;
}
.secnd-track {
  position: relative;
  top: 7rem;
  left: -2rem;
}
.third-track {
  margin-bottom: 15rem;
}
.fourth-track {
  position: relative;
  left: 0rem;
}
.apart-right {
  margin-bottom: 25px;
  padding-right: 130px;
  display: flex;
}
.apart-opicty {
  opacity: 0.6;
}
.borderLeft {
  border-left: 4px solid #80cbc4;
}
.apart-opicty .borderLeft {
  border-left: 4px solid #232233;
  opacity: 0.1;
}
.apart-box {
  border-radius: 8px;
  padding: 10px 30px;
  background: rgba(211, 237, 234, 0.2);
}
.apart-box h5 {
  color: #1a1a1a;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px; /* 150% */
}
.apart-box p {
  color: #1a1a1a;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.homepage-slider {
  padding-bottom: 90px;
}
.banner-content h2 {
  font-weight: 700;
  font-size: 44px;
}

.banner-content p {
  font-weight: 400;
  font-size: 20px;
  color: #7e7e7e;
  margin: 30px 30px 30px 0;
}

.about-us {
  text-align: center;
  padding: 60px 0px;
  background-image: url("../../public/assets/about-banner.svg");
}
.about-us button {
  min-width: 244px;
  max-width: 244px;
}
.about-us p {
  padding-bottom: 40px;
  color: #7e7e7e;
  line-height: 28px;
}
.about-box p {
  padding-bottom: 0px !important;
  margin-bottom: 0px;
  color: #808080 !important;
  line-height: 24px !important;
}
.about-box {
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 20px;
  background-color: #fff;
  text-align: center;
}

.banner-content button {
  width: 35%;
  min-width: 244px;
}

.img-bg {
  background-color: #d3edea;
  border-radius: 8px;
  text-align: center;
  margin: 0 auto;
  padding: 13px;
  width: 100px;
}

.about-grid {
  display: grid;
  grid-template-columns: 31% 31% 31%;
  gap: 40px;
}

.about-box {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 20px;
  padding: 26px;
}

.about-box h4 {
  font-weight: 600;
  font-size: 19px;
  padding: 30px 0px 16px;
}

.about-box p {
  font-weight: 500;
  font-size: 13px;
}
.contact-box {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 20px;
  padding: 30px;
  max-width: 499px;
  min-width: 499px;
  /* margin: 0px auto; */
}

.form-box {
  max-width: 360px;
  max-height: 540px;
  padding: 79px 40px;
  border-radius: 0px 16px 16px 0px;
  background-color: #80cbc4;
}
.num {
  width: 89px;
  height: 29px;
  border-radius: 14px;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  margin-right: 20px;
  text-align: center;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.form-box h4 {
  color: #fff;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: 25px;
}
.form-box p {
  color: #fff;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
.new-contact h2 {
  font-weight: 700;
  font-size: 30px;
  color: #1a1a1a;
  text-align: left;
  padding: 10px 0px 30px;
}

.input-date-box {
  border: 1px solid #eff0f7;
  box-shadow: 0px 2px 6px #13124212;
  border-radius: 8px;
  height: 47px;
  padding: 0px 10px;
  font-size: 16px;
  width: 100%;
}
.kLKOeb {
  overflow: visible !important;
}
.input-box {
  border: 1px solid #eff0f7;
  box-shadow: 0px 2px 6px #13124212;
  border-radius: 8px;
  height: 54px;
  padding: 0px 10px;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  color: #1a1a1a;
}

.ant-select-selection-item {
  font-size: 18px;
  font-weight: 400;
  color: #1a1a1a;
  font-family: Raleway;
}
::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="rgb(208, 0, 75)" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}
.fund-input {
  height: 66px !important;
}

.mobile-news-card {
  display: none;
}
.margin-mosque {
  margin-right: 70px;
}
.new-contact {
  margin-bottom: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .customDatePicker input {
    background-image: url("../components/Icon/formCalendar.svg");
    background-repeat: no-repeat;
    background-position: 95% 50%;
} */

/* .contact-form {
    display: grid;
    grid-template-columns: 50% 45%;
    gap: 50px;
} */

.social-icon {
  margin-bottom: 20px;
}

.social-icon img {
  margin-right: 20px;
}

.location_icon img {
  margin-right: 10px;
}

.location_icon a {
  text-decoration: none;
  color: #1a1a1a;
}

.contact-box button {
  width: 100%;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}

.contact-form h5 {
  font-weight: 500;
  font-size: 20px;
  color: #1a1a1a;
}

.onboarding-bg {
  padding: 40px 0px;
  /* background: url(../../public/homebg.png),
     linear-gradient(180deg, rgba(211, 237, 234, 0) 0%, 
     rgba(211, 237, 234, 0.5) 22.92%, rgba(211, 237, 234, 0.7) 49.48%, 
     rgba(211, 237, 234, 0.5) 72.92%, rgba(211, 237, 234, 0) 100%); */
  background-position: 100%;
}

.onboarding-bg h2 {
  font-weight: 700;
  font-size: 30px;
  line-height: 52px;
  text-align: center;
  color: #1a1a1a;
  padding: 36px 0px 0px 0px;
}
.onboarding-bg p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6c6c72;
  text-align: center;
}
.board-box {
  background: #ffffff;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: rgba(26, 26, 26, 0.2);
  /* 6 */
  /* max-height: 563px;
min-height: 563px; */
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 0px 0px 20px 20px;
  width: 698px;
  margin: 0 auto;
  padding: 50px;
}

.tab-active {
  background: #d0004b;
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: rgba(26, 26, 26, 0.2);
  border-radius: 12px 12px 0px 0px;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
}

.information-modal {
  list-style: none;
  text-align: center;
  padding: 0px;
  width: 698px;
  height: 76px;
  margin: 0 auto;
}

.information-modal li {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #d0004b;
  border-radius: 12px 12px 0px 0px;
  border: 1px solid #d7d7d7;
}

.border_bottom {
  border-bottom: 1px solid #d9dbe9;
  padding-top: 50px;
}
.input-box-profile-modal {
  font-size: 18px;
  font-weight: 600;
  font-family: Raleway;
}

.add-input-field-margin {
  margin: 15px 0;
}
.headingSix {
  font-size: 18px;
  font-weight: 600;
  font-family: Raleway;
}

.grey-border {
  border-top: 1px solid #d9dbe9;
}
.add-input-field-margin-profile-modal {
  margin: 10px 0;
}
.mosque-form h4 {
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #1a1a1a;
  margin: 30px 0px;
}

.mosque-form label {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 6px;
  color: #7e7e7e;
}

.gray-bt {
  background: #a0aec0;
  border-radius: 800px;
  color: #fff;
  border: 0px;
  font-weight: 800;
  font-size: 18px;
  width: 166px;
  height: 60px;
}
.date_class .ant-picker {
  width: 100% !important;
  height: 66px !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #eff0f7;
  box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
  border-radius: 8px;
  height: 54px;
}
.subscription-form {
  height: 500px;
  padding-right: 13%;
}
.form-height {
  height: 500px;
}
.ant-picker-clear {
  display: none;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  color: #1a1a1a;
}

.fill-bt {
  border-radius: 800px;
  color: #fff;
  border: 0px;
  font-weight: 800;
  width: 166px;
  height: 60px;
  background-color: #d0004b !important;
}

.progress-dialog {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.progress-container {
  width: 100%;
  height: 12px; /* Adjust height based on your design */
  background-color: #e2f1f0; /* Light background color */
  border-radius: 6px; /* Round corners */
  overflow: hidden;
  margin-bottom: 30px;
}

.progress-bar {
  height: 100%;
  background-color: #80cbc4 !important; /* Main progress color */
  border-radius: 6px; /* Rounded corners for progress */
  transition: width 0.4s ease;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

.prev-bt {
  font-weight: 800;
  width: 197px;
  height: 60px;
  color: #d0004b;
  border: 2px solid #d0004b;
  border-radius: 800px;
  background-color: #fff;
}
.prev-bt-disabled {
  font-weight: 800;
  width: 197px;
  height: 60px;
  color: #7e7e7e;
  border: 2px solid #7e7e7e;
  border-radius: 800px;
  background-color: #fff;
}
.error-box {
  background: #ffffff;
  border: 1px solid #eff0f7;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 20px;
  padding: 10px;
  text-align: center;
  width: 698px;
  margin: 0 auto;
}

.error-box p {
  font-weight: 400;
  font-size: 18px;
  color: #fe1717;
  margin-bottom: 0px;
}

.error-box a {
  color: #d0004b;
  font-weight: 600;
  font-size: 18px;
  padding-left: 10px;
  text-decoration: underline;
}

.error-msg {
  color: red;
  font-size: 13px;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 12px;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #d9dbe9;
  border-radius: 4px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #d0004b;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* end */

#progressbar {
  overflow: hidden;
  color: #455a64;
  padding-left: 0px;
}

#progressbar li {
  list-style-type: none;
  width: 32.66%;
  float: left;
  position: relative;
  font-weight: 400;
}

#progressbar #step1:before {
  content: "1";
}

#progressbar #step2:before {
  content: "2";
}

#progressbar #step3:before {
  content: "3";
}

#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background: url(https://ik.imagekit.io/rzlzf40okya/elixir/gray_circle.svg);
  background-size: cover;
  margin: auto;
  color: #7e7e7e;
  z-index: 11;
  position: relative;
}

#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  /* background: rgba(0, 0, 0, 0.2); */
  position: absolute;
  left: 0;
  top: 21px;
  z-index: 1;
  border-top: 8px solid #eff0f7;
}

#progressbar li:last-child:after {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  left: -50%;
}

#progressbar li:first-child:after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  left: 50%;
}

#progressbar li:last-child:after {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background-image: url(https://ik.imagekit.io/rzlzf40okya/elixir/red_circle.svg);
  border-color: #d0004b;
  color: #fff;
}

.congrats-screen {
  text-align: center;
}

/* select plan css starts */
.PlanCards {
  position: relative;
  overflow: hidden;
  padding: 30px 10px 10px 10px;
  background: #ffffff;
  border: 0.804144px solid #eff0f7;
  box-shadow: 0px 2.37681px 16.6376px rgba(98, 106, 128, 0.16);
  border-radius: 9px;
  margin-bottom: 20px;
}

.PlanCards .customCheckbox {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 0px 9px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PlanCards .customCheckboxGrey {
  background: #e9e9e9;
}

.PlanCards .customCheckboxGreen {
  background: #00ac4f;
}

.PlanCards .planNo {
  font-weight: 700;
  font-size: 15px;
  color: #1a1a1a;
  text-decoration-line: underline;
}

.PlanCards .planNo span {
  font-weight: 700;
  font-size: 15px;
  color: #80cbc4;
  text-transform: uppercase;
  text-decoration-line: underline;
}

.PlanCards .Dashborder {
  border-top: 1px dashed #a0aec0;
  margin: 10px 0;
}

.PlanCards .price {
  font-weight: 700;
  font-size: 28.5px;
  color: #1a1a1a;
}

.PlanCards .price del {
  font-weight: 600;
  font-size: 13.5px;
  color: #a0aec0;
  margin-left: 10px;
}

.PlanCards .planBtn {
  border-radius: 150px;
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  height: 33px;
  width: 100%;
  border: none;
  margin: 10px 0;
}

.PlanCards .planBtnEnable {
  background: linear-gradient(107.91deg, #ff7aaa 7.37%, #d0004b 95.19%);
}

.PlanCards .planBtnDisable {
  background: #a0aec0;
}

.PlanCards .planDetail {
  font-weight: 600;
  font-size: 13.5px;
  color: #1a1a1a;
}

.PlanCards ul {
  margin-bottom: 0px;
}

.PlanCards li {
  font-weight: 500;
  font-size: 12px;
  color: rgba(26, 26, 26, 0.67);
}

/* select plan css ends */
.customer_img {
  width: 100%;
  height: 166px;
  border-radius: 8px;
  object-fit: cover;
}
.mosque-bg .slick-slide {
  padding: 10px;
}

/* customer css */

.payment-footer {
  min-height: 680px;
}
.plan-footer {
  min-height: 680px;
}
.contact-footer {
  min-height: 687px;
}
.new-contact,
contact-header {
  padding-top: 54px;
}
.new-contact .plan-header {
  padding-top: 54px;
}
.new-contact .payment-header {
  padding-top: 32px;
}
.mosque-bg {
  padding-bottom: 100px !important;
}
.mosque-bg h3 {
  margin: 20px 0px 30px;
}
.mosque-bg .slick-prev:before {
  content: url("https://ik.imagekit.io/rzlzf40okya/elixir/prev.svg");
}
.mosque-bg .slick-next:before {
  content: url("https://ik.imagekit.io/rzlzf40okya/elixir/next.svg");
}
.mosque-bg .slick-prev {
  left: -40px;
}
.customer_card {
  min-height: 400px;
}
.customer_card {
  background: #ffffff;
  border: 1px solid #eff0f7;
  border-radius: 12px;
  padding: 14px;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
}
.customer_card h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #1a1a1a;
  word-wrap: break-word;
}
.mobile-history {
  display: none;
}
.customer_card button {
  font-weight: 700;
  font-size: 14px;
  min-width: 100px;
  min-height: 32px;
}
/*.customer_card h4{
    height: 100px; 
}*/

.auth_img {
  width: 36px !important;
  height: 36px !important;
  border-radius: 50%;
  object-fit: cover;
}

.contact-profile {
  margin-bottom: 90px;
}

.PlanCards .plan-li {
  margin-top: 7px;
  margin-bottom: 7px;
}

.mosque_blog_img {
  border-radius: 8px;
  height: 240.47px;
  /* object-fit: contain; */
}

.label-missed-payments {
  color: #7e7e7e;
  margin-bottom: 5px;
}

.label-sent-msg {
  color: #7e7e7e;
}

.label-message {
  color: #7e7e7e;
  margin-right: 246px;
}

.label-msg {
  color: #1a1a1a;
  font-size: 14px;
}

/* .dotted-line {
    border-top: 1px dotted #000;
    margin-top: 10px;
  } */

.date-container {
  position: relative;
}

.vertical-dotted-line {
  position: absolute;
  top: 0;
  left: 25%;
  width: 1px;
  height: 100%;
  transform: translateX(-50%);
  border-left: 1px dotted #a0aec0;
}

.fund-card-img-size {
  width: 300px;
  height: 200px;
  border-radius: 8px;
}

.mosque-blog-img {
  height: 28vw;
}

.payment-small-details {
  margin-bottom: 3px;
}
.next-payment-date-text {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #fe1717;
}
.headingTwo {
  font-family: Raleway;
  font-size: 22px;
  font-weight: 700;
  line-height: 25.83px;
  text-transform: capitalize;
}

.next-payment-date-wrapper {
  width: 154px;
  height: 28px;
  padding: 6px 12px;
  border-radius: 4px;
  opacity: 0px;
  background: #ffd5d5;
  margin: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.payment-history-table {
  padding-top: 100px;
  margin-bottom: 250px;
}

.blog-card .h4-height {
  min-height: 84px;
}

.blog-detail-img-height {
  height: 30vw;
  object-fit: contain;
}

.member-info-table {
  height: 820px;
}

.congratulation-button {
  padding: 10px 20px;
  width: 170px;
  background-color: #d0004b;
  border: solid 1px #d0004b;
  color: white;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 800;
}

.mosque-unpaid-table {
  height: 870px;
}

.mosque-unpaid-small-table {
  height: 120px;
}

.contact-heading-five {
  padding-bottom: 20px;
}

.location_icon_spacing {
  padding-bottom: 14px;
}

/* Media Query starts */

@media only screen and (min-width: 993px) {
  .for-mobile {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .for-desktop {
    display: none;
  }
  .feature-bg img {
    width: 100%;
    transform: translateY(0px);
  }

  .first-track {
    position: relative;
    margin: 5.5rem 0rem;
    left: 0rem;
  }

  .banner-grid-for-mobile {
    display: flex;
    flex-direction: column;
    padding: 85px;
    text-align: center;
  }

  .banner-grid-for-mobile img {
    width: 100%;
    border-radius: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .feature-sec h4 {
    font-weight: 600;
    line-height: 15.864px;
    font-size: 16px;
  }
  .apart-box p {
    font-size: 14px;
    line-height: 21px;
  }
  .apart-box h5 {
    color: #1a1a1a;
    font-family: Raleway;
    font-size: 16px;
  }
  .feature-sec {
    background: #fafafa;
    padding: 2px 0px 0px 0px;
  }
  .row.mobile-row {
    flex-wrap: unset;
    overflow: scroll;
  }
  .about-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 40px;
  }
  .payment-history-table {
    margin-bottom: 0px;
    padding-top: 22%;
    padding-bottom: 25%;
  }
  .customer_card h4 {
    font-size: 14px;
    line-height: 20px;
  }
  .payment-box {
    box-shadow: none;
    background-color: transparent;
  }
  .mobile-history .table-col {
    font-size: 10px;
    width: auto;
    padding: 10px 10px 10px 0px;
    min-height: 53px;
  }
  .table-row {
    display: grid;
    grid-template-columns: 5% 20% 40% 10% 10% !important;
    width: auto;
    clear: both;
  }
  .table_body {
    display: grid;
    grid-template-columns: 5% 20% 40% 10% 10% !important;
  }
  .mobile-history {
    display: block;
  }
  .desktop-history {
    display: none;
  }

  .customer_card {
    max-height: 385px;
  }
  ul {
    padding-left: 0rem;
  }
  .contact-form {
    display: flex;
    flex-direction: column;
  }

  .contact-box {
    margin: 0px 0px;
    padding: 30px 10px;
  }

  .board-box {
    width: 100%;
    padding: 30px;
  }

  .error-box {
    width: 100%;
  }

  .information-modal {
    width: 100%;
  }

  .banner-grid-for-mobile {
    padding: 50px 50px 0 50px;
    background-image: url("../../public/assets/mobileEXPORT.svg");
    height: 485px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100%;
  }
  .d-deskotop {
    display: none;
  }
  .d-mobile {
    display: block;
  }
  .apart-sec img {
    width: 100%;
  }
  .apart-right {
    margin-bottom: 25px;
    padding-right: 0px;
  }
  .first-track {
    margin: 2.5rem 0rem;
    display: flex;
    align-items: flex-start;
  }
  .first-track img {
    margin-right: 10px;
    width: 25px;
  }
  .secnd-track img {
    margin-right: 10px;
  }
  .third-track img {
    margin-right: 10px;
  }
  .fourth-track img {
    margin-right: 10px;
  }
  .fourth-track {
    display: flex;
    left: 0rem;
    align-items: flex-start;
  }
  .secnd-track {
    top: 0rem;
    left: 0rem;
    display: flex;
    align-items: flex-start;
  }
  .third-track {
    display: flex;
    align-items: flex-start;
  }
  .third-track {
    margin-bottom: 1rem;
  }
  .left-track {
    text-align: left;
  }
  .tab_bg {
    padding: 0 20px;
  }
  .mobile-news-card {
    display: block;
  }
  .desktop-news-card {
    display: none;
  }
  .mosque-blog-img {
    height: 170px;
    object-fit: cover;
  }
  .new-contact {
    display: block;
  }
  .contact-box {
    max-width: 100%;
    min-width: 100%;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 9.61924px 0px 25.6513px 0px rgba(0, 0, 0, 0.1);
  }
  .form-box {
    max-width: 320px;
    max-height: auto;
    padding: 20px 15px;
    border-radius: 0px 0px 20px 20px;
    background-color: #80cbc4;
    margin: 0 auto;
  }
  .form-box h4 {
    font-size: 16px;
  }
  .form-box p {
    font-size: 12px;
    line-height: 20px;
  }
  .about-us p {
    padding-bottom: 40px;
    color: #7e7e7e;
    line-height: 22px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 576px) {
  .about-grid {
    display: grid;
    grid-template-columns: 100%;
    gap: 40px;
  }

  .information-modal li {
    font-size: 16px;
  }

  .board-box {
    padding: 20px 10px;
  }

  .gray-bt,
  .prev-bt,
  .fill-bt {
    width: 100%;
    font-size: 12px;
    max-width: 150px;
    height: 44px;
  }

  .banner-content h2 {
    font-size: 20px;
  }

  .banner-content p {
    font-size: 12px;
    margin: 30px 0;
  }

  .onboarding-bg h2 {
    font-size: 20px;
    padding: 30px 0px;
  }

  #about-state {
    margin: 0 20px;
    width: auto;
    padding: 0;
  }

  .new-contact h2 {
    font-size: 20px;
    padding: 0px;
  }
  .mosque-bg .slick-prev {
    left: -8px !important;
    z-index: 99;
  }
  .mosque-bg .slick-next {
    right: 10px;
  }
}
@media screen and (max-height: 1200px) {
  .member-info-table {
    height: 100% !important;
  }
  .member-table-body {
    max-height: none;
  }
}
#btn-margin-top {
  margin-top: 50px !important;
}
/* Media Query ends */
